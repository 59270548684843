<template>

  <div v-if="edited_week && props.diary && allowEdit">  

    <WeekEditFazaHarSmoke 
      v-if="edited_week.faza == 2" 
      :week="edited_week" 
      :diary="diary" 
      :is-loading-save="isLoadingSave"
      @updateweek="emits('updateweek')" 
      @saveweek="saveWeek($event)"
      @savesmoke="saveSmoke($event)"
      @canceleditweek="emits('canceleditweek', $event)" 
      />    
 
  </div>

  <div v-else-if="props.diary?.id && !allowEdit">
    <UiNoAccess />
  </div>

</template>

<script setup>


const route = useRoute();
const { $api, $ga, $helper, $head, $popup, $vartpl, $convert } = useNuxtApp();
const { t } = useI18n();
const link = ref(route.params['week']);
const isLoadingSave = ref(false);
const edited_week = ref(null);
const original_week = ref(null);
const emits = defineEmits(['saveWeek', 'updateweek', 'canceleditweek']);

const props = defineProps({
  week: {
    type: Object,  
  },
  diary: {
    type: Object,            
    required: true,
  }
});


const prepareData = () => {

  console.log(original_week);

  if(isNaN(link.value)){       

    original_week.value = JSON.parse(JSON.stringify($vartpl.week));

    if(link.value.substr(4,1) == 'g'){
      original_week.value.faza = -1;     
      original_week.value.item_germination.items_seed = props.diary.items_equip.filter(e => e.category == 'seed');
    }else if(link.value.substr(4,1) == 'v'){
    console.log('veg');
      original_week.value.faza = 0;        
    }else if(link.value.substr(4,1) == 'f'){
      original_week.value.faza = 1;        
    }else if(link.value.substr(4,1) == 'h'){
      original_week.value.faza = 2;        
    }
    console.log('prepare');
    console.log(link.value.substr(4,1));
    console.log(original_week.value.faza);
    
  }else{ 

    original_week.value = JSON.parse(JSON.stringify(props.week));

    convertUnitsForEdit(original_week.value);
  }


  edited_week.value = JSON.parse(JSON.stringify(original_week.value));

  // console.log('edited_week.value');
  // console.log(edited_week.value);
}

 

const validateWeek = (edited_week) => {  
  if(edited_week.faza == '-1'){

  }else if(edited_week.faza == '2' && edited_week.is_smoke == 1){
    if(!edited_week.items_photo?.length){
      $popup.error('Empty photos');
      return false;
    }
  }else{

    if(!edited_week.days){
      $popup.error('Empty week number');
      return false;
    }
    if(!edited_week.items_photo?.length){
      $popup.error('Empty photos');
      return false;
    }
    if(!edited_week.text){
      $popup.error('Empty comment field');
      return false;
    }
  }
  return true;
} 

// const saveSmoke = (edited_week) => {   
//   edited_week.is_smoke = 1;
//   console.log(edited_week)
//   saveWeek(edited_week);
// }



const convertUnitsForSave = (w) => {
  if(w.faza == 2){
    var wd = w.item_harvest;
    w.props.harvest_weight = $convert.legitimed('weight', useAuth().getWeight(), w.props.harvest_weight, 5);
    w.props.harvest_wet_weight = $convert.legitimed('weight', useAuth().getWeight(), w.props.harvest_wet_weight, 5); 
    w.props.harvest_space = $convert.legitimed('length', useAuth().getLength(), w.props.harvest_space, 5);
  }
}

const convertUnitsForEdit = (w) => {

// return false;

if(w.faza == 2){
  var wd = w.item_harvest;
  w.props.harvest_weight = $convert.universal('weight', useAuth().getWeight(), w.props.harvest_weight);
  w.props.harvest_wet_weight = $convert.universal('weight', useAuth().getWeight(), w.props.harvest_wet_weight); 
  w.props.harvest_space = $convert.universal('length', useAuth().getLength(), w.props.harvest_space);
} 



}

const saveWeek = (edited_week) => {   
  
  if(!validateWeek(edited_week)) 
    return false;         

  isLoadingSave.value = true;

  


  var saving_data = JSON.parse(JSON.stringify(edited_week));

  convertUnitsForSave(saving_data);

  var form = saving_data;    


  if(!form.id){   
    $api.postWeek(props.diary.id, form) 
    .then((res) => {
      emits('updateweek', res.data.value.data.id);              
      isLoadingSave.value = false;
    })
    .catch(function (error) { 
      isLoadingSave.value = false;
      // $popup.error(t('create_week_view_error_failed'));
      $popup.error('Error');
    });  
  }else{
    $api.putWeek(props.diary.id, form.id, form) 
    .then((res) => {      
      isLoadingSave.value = false;
      console.log('saved week id');      
      console.log(res.data.value.data);      
      emits('updateweek', res.data.value.data.id);    
    })
    .catch(function (error) { 
      isLoadingSave.value = false;
      // $popup.error(t('create_week_view_error_failed'));    
      $popup.error('Error');    
    });
  }
}

const allowEdit = computed(() => {
  if(props.diary.item_user.id == useAuth().getId())
    return true;
  return false;
});
 



onMounted(() => {  
  prepareData();
});


onUnmounted(() => {  
  edited_week.value = null;
});




</script>

<style scoped>
 
</style>
